import {download as d0} from './download-snapshot-container'
import {download as d1} from './download-snapshot-desktop-linux-amd64'
import {download as d2} from './download-snapshot-desktop-macosx-aarch64'
import {download as d3} from './download-snapshot-desktop-windows-amd64'
import {download as d4} from './download-snapshot-server'

export const downloadList = [
d0,
d1,
d2,
d3,
d4,
];
